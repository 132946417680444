import {CULTIVATION_DASHBOARD, FINANCE_DASHBOARD, PROCESSING_GROUPS} from "../../config/routes";
import {useProfile} from "../../components/Auth/ProfileProvider";
import {useEffect} from "react";
import {useRouter} from "next/router";

export default function AppIndex() {
  const {profile, loading} = useProfile()
  const router = useRouter();
  useEffect(() => {
    if(!loading && !profile?.organizationId)
      router.push("/auth/login")
    else if (profile?.organization?.moduleEnabledCultivation)
      router.push(CULTIVATION_DASHBOARD)
    else if (profile?.organization?.moduleEnabledProcessing)
      router.push(PROCESSING_GROUPS)
    else if (profile?.organization?.moduleEnabledFinance)
      router.push(FINANCE_DASHBOARD)
  }, [profile?.organizationId, profile?.organization, loading])
  return <>Redirecting...</>;
};
